import controlNames from "@constants/controlNames";
import AppStore from "../../store/AppStore";

export const SetPreviousVehicleVisibility = (controlVisibility) => {
        AppStore.setPreviousVehicleInitialControVisibility(controlVisibility);
        AppStore.setVisibilityControls({
            [controlNames.previousVehicleRadio]: controlVisibility,
        });

        if(controlVisibility !== true)
            AppStore.deleteFormData(controlNames.previousVehicleRadio);
    };

export const  SetPreviousVehicleDependentControlsVisibility = (controlVisibility) => {
        [
            controlNames.previousVehicleSoldDate, 
            controlNames.previousVehiclePurchaseDate
        ].forEach(controlName => {
            AppStore.setVisibilityControls({
                [controlName]: controlVisibility,
            });

            if(controlVisibility !== true)
                AppStore.deleteFormData(controlName);
        });  
    }
