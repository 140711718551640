export const Categories = {
  QuestionSetCompletion: "QS_Completion",
  API: "API",
  Environment: "Environment",
  General: "General",
  UserJourneyAcquisition: "User journey acquisition",
};

export const Actions = {
  StartQuote: "start_quote",
  ContinueQuote: "continue_quote",
  FinishedQuote: "finished_quote",
  LoadFormCall: "load_form_call",
  AmendQuote: "amend_quote",
  PrintResult: "print_result",
  QuoteSummary: "quote_summary",
  Environment: "get_environment",
  LogoRedirectPopupOpen: "logo_redirect_popup_open",
  LogoRedirectPopupClose: "logo_redirect_popup_close",
  LogoUserRedirected: "logo_user_redirected",
  QueryParameter: "query_parameter",
  ErrorMessage: "error_message",
};

export const Messages = {
  LoadFormError: "LoadForm Error",
  SwitchPage: "Moved to ",
  FinishedQuote: "User completed journey",
  AmendQuote: "User amended quote",
  PrintResult: "User clicked print results",
  QuoteSummary: "User opened quote summary",
  EmptyMessage: "",
  LogoRedirectPopupOpen: "User clicks on logo",
  LogoRedirectPopupClose: "User closes logo redirect popup",
  LogoUserRedirected: "User is redirected to whitelabel homepage",
};

export const Labels = {
  Status: "Status",
  FinishedQuote: "FinishedQuote",
  SwitchPage: "ChangePage",
  AmendQuote: "AmendQuote",
  PrintResult: "PrintResult",
  QuoteSummary: "QuoteSummary",
  UserAgent: "UserAgent",
  QuoteRef: "QuoteReference",
  Logo: "Logo",
  RefId: "refid",
};

export const Cookies = {
  CSCookie: "csid",
  FirstInteractionCookie: "fi",
};
