import React from "react";
class Error extends React.Component {
  render() {
    const errorClass =
      this.props.validation && this.props.validation.isValid === false ? "error" : "";
    return (
      <React.Fragment>
        <div
          className={`error-message-container col-lg-4 col-md-6 col-sm-12 col-12 ${this.props.className}`}>
          <div
            className={errorClass}
            data-testid={`error-${this.props.name ?? ""}-${!this.props.validation
              .isValid}`}>
            {this.props.validation && this.props.validation.isValid === false
              ? this.props.validation.messages[0]
              : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Error;
