import controlNames from "@constants/controlNames";
import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import AppStore from "@store/AppStore";

function handleSubQuestionsPreRender(radioName, dropdownName) {
  const dropdownValue = AppStore.getControlByName(dropdownName);
  const radioValue = AppStore.getControlByName(radioName);

  if (isNullOrUndefined(radioValue) || radioValue === "") {
    AppStore.setVisibilityControls({ [dropdownName]: false });
    AppStore.deleteFormData(dropdownName);
  } else if (radioValue === "no") {
    AppStore.setFormData({ [dropdownName]: "NONE" });
  }

  if (dropdownValue === "NONE") {
    AppStore.setVisibilityControls({ [dropdownName]: false });
    AppStore.setFormData({ [radioName]: "no" });
  }
}

function handleSubQuestionsOnChange(radioName, dropdownName, radioValue) {
  if (radioValue === "no") {
    AppStore.setFormData({ [dropdownName]: "NONE" });
  }
}

const questionsMapping = {
  [controlNames.securityDevicesBoolean]: controlNames.securityDevices,
  [controlNames.trackerBoolean]: controlNames.tracker,
  [controlNames.markingsBoolean]: controlNames.markings,
};

const namesToCheck = Object.keys(questionsMapping);

function manipulateControlsPreRender(controlName) {
  if (namesToCheck.includes(controlName)) {
    handleSubQuestionsPreRender(controlName, questionsMapping[controlName]);
  }
}

function manipulateControlsOnChange(controlName, controlValue) {
  if (namesToCheck.includes(controlName)) {
    handleSubQuestionsOnChange(controlName, questionsMapping[controlName], controlValue);
  }
}

function manipulateControls(controlName, controlValue) {
  if (controlName === "security-devices") {
    if (controlValue === "no" || controlValue === false) {
        AppStore.setFormData({
          "security-devices-dropdown": "NONE",
        });
    }
  } else if (controlName === "tracker") {
    if (controlValue === "no" || controlValue === false) {
      AppStore.setFormData({
        "tracker-dropdown": "NONE",
      });
    }
  }
}

export default {
  manipulateControlsPreRender,
  manipulateControlsOnChange,
  manipulateControls,
};
