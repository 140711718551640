import controlNames from "@constants/controlNames";
import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import AppStore from "@store/AppStore";
import ClientLogging from "../../providers/loggingProvider";

const checkNoneValue = (controlName, subControlName, noneValue) => {
    const subControlValue = AppStore.getControlByName(subControlName);
    if (
        isNullOrUndefined(subControlValue) ||
        subControlValue === "" ||
        subControlValue === noneValue
    ) {
        AppStore.setVisibilityControls({ [subControlName]: false });
        if (subControlValue === noneValue) {
            AppStore.setFormData({ [controlName]: "no" });
        }
    } else {
        AppStore.setVisibilityControls({ [subControlName]: true });
        AppStore.setFormData({ [controlName]: "yes" });
    }
};

const checkToSetNoneValue = (controlValue, valueForHide, subControlName, noneValue) => {
    if (controlValue === valueForHide) {
        AppStore.setFormData({ [subControlName]: noneValue });
    }
};

function manipulateControlsPreRender(controlName) {
    try {
        const controlValue = AppStore.getControlByName(controlName);
        if (
            controlName === "uk-resident-since-radio" ||
            controlName === "uk-resident-since-radio-additional"
        ) {
            const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
            let residentSince = AppStore.getControlByName(`uk-resident-since${additionalSuffix}`);
            let dob = AppStore.getControlByName(`date-of-birth${additionalSuffix}`);

            if (dob !== residentSince && dob) {
                AppStore.setFormData({
                    [`uk-resident-since-radio${additionalSuffix}`]: "no",
                });
                AppStore.setVisibilityControls({
                    [`uk-resident-since${additionalSuffix}`]: true,
                });
                AppStore.setVisibilityControls({
                    [`uk-resident-since${additionalSuffix}`]: residentSince
                });
            }
        }
        else if (
            controlName === controlNames.dvlaMedicalBoolean ||
            controlName === "dvla-medical-boolean-additional"
        ) {
            const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
            const medName = `dvla-medical${additionalSuffix}`;
            checkNoneValue(controlName, medName, "N");
            checkToSetNoneValue((controlValue ?? "no"), "no", medName, "N");
        } 
        else if (controlName === controlNames.keeperOwner) {
            const keeperValue = AppStore.getControlByName(controlNames.keeper);
            const ownerValue = AppStore.getControlByName(controlNames.owner);

            if (
                [keeperValue, ownerValue].every((subControlValue) => {
                    return (
                        isNullOrUndefined(subControlValue) ||
                        subControlValue === "" ||
                        subControlValue === "PR"
                    );
                })
            ) {
                AppStore.setVisibilityControls({ [controlNames.keeper]: false });
                AppStore.setVisibilityControls({ [controlNames.owner]: false });

                if (controlValue === "yes") {
                    AppStore.setFormData({ [controlNames.keeper]: "PR" });
                    AppStore.setFormData({ [controlNames.keeper]: "PR" });
                }
            } else {
                AppStore.setVisibilityControls({ [controlNames.keeper]: true });
                AppStore.setVisibilityControls({ [controlNames.owner]: true });
                AppStore.setFormData({ [controlName]: "no" });
            }
        } else if (controlName === controlNames.membershipIdRadioControlName && controlValue === "no") {
            AppStore.setFormData({ [controlNames.membershipIdRadioControlName]: "" });
        }
        else if (controlName === controlNames.businessNotEstablished) {
            if ((
                controlValue === "false"
                && AppStore.getControlByName(controlNames.businessEstablished) === "Not Applicable"
            ) || controlValue === "true"
            ) {
                AppStore.setFormData({ [controlNames.businessNotEstablished]: "true" });
                AppStore.setFormData({ [controlNames.businessEstablished]: "Not Applicable" });
                AppStore.setVisibilityControls({ [controlNames.businessEstablished]: false }); 
            }
        }
    } catch (ex) {
        ClientLogging.logError(ex, "Handle controls pre render method - radio logic");
    }
}

function manipulateControlsOnChange(controlName, controlValue) {
  if (
    controlName === controlNames.dvlaMedicalBoolean ||
    controlName === "dvla-medical-boolean-additional"
  ) {
    const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
    const medName = `dvla-medical${additionalSuffix}`;
    checkToSetNoneValue(controlValue, "no", medName, "N");
  }
  else if (controlName === controlNames.keeperOwner) {
    if (controlValue === "yes") {
      if (import.meta.env.VITE_PRODUCT === "car" || import.meta.env.VITE_PRODUCT === "van")
        AppStore.setFormData({ [controlNames.registeredKeeper]: "PR" });
      else AppStore.setFormData({ [controlNames.keeper]: "PR" });
      AppStore.setFormData({ [controlNames.owner]: "PR" });
    }
    }
  else if (
    controlName === controlNames.businessNotEstablished
    && controlValue === "true"
  ) {
    AppStore.setFormData({ [controlNames.businessEstablished]: "Not Applicable" });
    AppStore.setVisibilityControls({ [controlNames.businessEstablished]: false });
  }
}

function manipulateControls(controlName, controlValue) {
    try {
        if (controlName.includes("uk-resident-since-radio")) {
            const additionalName = controlName.includes("additional") ? "-additional" : "";
            const residentSinceName = "uk-resident-since" + additionalName;
            const dobName = "date-of-birth" + additionalName;

            if (controlValue === "yes") {
                AppStore.setFormData({
                    [residentSinceName]: AppStore.getControlByName(dobName),
                });
            }
        }
        else if (controlName === "official-uk-model") {
            let mhOfficialUkModel = !isNullOrUndefined(
                AppStore.getControlByLogicalName("mh-official-uk-model")
            )
                ? AppStore.getControlByLogicalName("mh-official-uk-model")
                : "";
            if (mhOfficialUkModel !== "") {
                if (mhOfficialUkModel === "no" || mhOfficialUkModel === false) {
                    AppStore.setVisibilityControls({
                        "vehicle-imported": true,
                    });
                } else {
                    AppStore.setVisibilityControls({
                        "vehicle-imported": false,
                    });
                    AppStore.deleteFormData("vehicle-imported");
                }
            }
        }
        else if (controlName === "club-member") {
            if (controlValue === "no" || controlValue === false) {
                AppStore.setFormData({
                    "club-member-dropdown": "NONE",
                });
            }
        }
        else if (controlName === "keeper-owner") {
            const registerKeeperVal = AppStore.getControlByName("register-keeper");
            const legalOwnerVal = AppStore.getControlByName("legal-owner");
            const keeperOwnerVal = AppStore.getControlByName("keeper-owner");
            // controlValue is empty when doing a recall from old TBI and
            // we need to update it based on legal-owner and keeper-owner
            if (
                isNullOrUndefined(controlValue) ||
                controlValue === "" ||
                controlValue === "yes"
            ) {
                if (
                    (!isNullOrUndefined(registerKeeperVal) &&
                        registerKeeperVal !== "PR" &&
                        registerKeeperVal !== "") ||
                    (!isNullOrUndefined(legalOwnerVal) &&
                        legalOwnerVal !== "PR" &&
                        legalOwnerVal !== "")
                ) {
                    AppStore.setVisibilityControls({
                        "register-keeper": true,
                    });
                    AppStore.setVisibilityControls({
                        "legal-owner": true,
                    });
                    AppStore.setFormData({ "keeper-owner": "no" });
                } else {
                    AppStore.setVisibilityControls({
                        "register-keeper": false,
                    });
                    AppStore.setVisibilityControls({
                        "legal-owner": false,
                    });
                    AppStore.setFormData({ "keeper-owner": "yes" });
                    AppStore.setFormData({ "register-keeper": "PR" });
                    AppStore.setFormData({ "legal-owner": "PR" });
                }
            }
        }
        else if (controlName === "official-uk-model") {
            let mhOfficialUkModel = !isNullOrUndefined(
                AppStore.getControlByLogicalName("mh-official-uk-model")
            )
                ? AppStore.getControlByLogicalName("mh-official-uk-model")
                : "";
            if (
                mhOfficialUkModel !== "" &&
                (mhOfficialUkModel === "yes" || mhOfficialUkModel === true)
            ) {
                AppStore.deleteFormData("vehicle-imported");
            }
        }
        else if (controlName === "liability") {
            if (controlValue === "no" || controlValue === false) {
                AppStore.setVisibilityControls({
                    "cost-of-claim": false,
                });
                AppStore.deleteFormData("cost-of-claim");
                AppStore.setVisibilityControls({
                    "cost-of-third-party-claim": false,
                });
                AppStore.deleteFormData("cost-of-third-party-claim");
            }
        } 
        else if (
            controlName === controlNames.businessNotEstablished
            && controlValue === "true"
        ) {
            AppStore.setFormData({ [controlNames.businessNotEstablished]: "true" });
            AppStore.setFormData({ [controlNames.businessEstablished]: "Not Applicable" });
            AppStore.setVisibilityControls({ [controlNames.businessEstablished]: false });
        }
    } catch (ex) {
        ClientLogging.logError(ex, "Manipulate control values - radio logic");
    }
}

export default {
    manipulateControlsPreRender,
    manipulateControlsOnChange,
    manipulateControls,
};
