import controlNames from "@constants/controlNames";
import controlsLogicHelper from "@helpers/controlsLogicHelper";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import ControlsFactory from "../../../factories/ControlsFactory";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import useWindowSize from "../../../helpers/windowSize";
import AppStore from "../../../store/AppStore";
import Error from "../../controls/StaticControls/Error.Component";
import Info from "../../controls/StaticControls/Info.Component";
import Label from "../../controls/StaticControls/Label.Component";
import Tooltip from "../../controls/StaticControls/Tooltip.Component";

const DateMonthYear = observer(
  ({
    id,
    name,
    label,
    handleDateChange,
    handleInteraction,
    currentValue,
    helpMessages,
    infoMessages,
    children,
    attributes,
    validation,
  }) => {
    let day;
    let month;
    let year;
    const dobValue = AppStore.getControlByName(controlNames.dateOfBirth);

    const customFormGroupClass = attributes?.customFormGroupClass ?? "";
    const checkDateChanged = (dateToSave = false) => {
      if (dateToSave) {
        day = dateToSave[2];
        month = dateToSave[1];
        year = dateToSave[0];
      } else {
        const date =
          isNullOrUndefined(currentValue) || currentValue === ""
            ? "YYYY-MM-DD"
            : currentValue;
        const dateSubfield = date.split("-");
        day = dateSubfield[2];
        month = dateSubfield[1];
        year = dateSubfield[0];
      }
      if (day !== "DD" && month !== "MM" && year !== "YYYY") return true;
      return false;
    };

    /**
     * When attributes.defaultDayValue is set to "current"
     * - For month and year which represent "today" month - return current day
     * - otherwise - return last day of the supplied year and month
     *
     * When attributes.defaultDayValue is set to "first" or unset
     * - return '01'
     *
     * @param {string | number} year e.g. 2024
     * @param {string} month in padded format e.g '01', '12'
     * @returns {string} padded day e.g. '01', '31'
     */
    const getDayForYearAndMonth = (year, month) => {
      switch (attributes.defaultDayValue) {
        case "current": {
          // 15 is a random day in a month to make date object valid
          const targetDate = dayjs(`${year}-${month}-15`);

          const day = targetDate.isSame(currentDate, "month")
            ? currentDate.date()
            : targetDate.daysInMonth();

          return ("" + day).padStart(2, "0");
        }
        case "first":
        default: {
          return "01";
        }
      }
    };

    let dateChanged = checkDateChanged();

    let currentDate = dayjs();
    let currentMonth = dayjs(currentDate).format("MM");
    let currentYear = dayjs(currentDate).format("YYYY");
    const errorClass = validation && validation.isValid === false ? "error" : "";
    let yearsRangeDifference =
      attributes !== null && !isNullOrUndefined(attributes.yearsRangeDifference)
        ? attributes.yearsRangeDifference
        : null;
    let yearsRangeDifferenceAgainst =
      attributes !== null && !isNullOrUndefined(attributes.yearsRangeDifferenceAgainst)
        ? attributes.yearsRangeDifferenceAgainst
        : null;
    let maxYearDifference =
      attributes !== null && !isNullOrUndefined(attributes.maxYearDifference)
        ? attributes.maxYearDifference
        : 0;
    let defaultYearsRange =
      attributes !== null && !isNullOrUndefined(attributes.defaultYearsRange)
        ? attributes.defaultYearsRange
        : null;

    let [subControl, setSubControl] = useState(false);

    useEffect(() => {
      setSubControl(attributes?.subControl);
    });

    if (!isNullOrUndefined(yearsRangeDifferenceAgainst)) {
      let quoteData = JSON.parse(AppStore.getUserData().collectedQuoteData);
      let yearsRangeDifferenceAgainstQuoteValue = 0;
      if (!isNullOrUndefined(quoteData)) {
        yearsRangeDifferenceAgainstQuoteValue = dayjs(
          quoteData[yearsRangeDifferenceAgainst],
          "YYYY-MM-DD"
        ).format("YYYY");
      }

      let diff =
        AppStore.getUIState().get(yearsRangeDifferenceAgainst) ??
        AppStore.formData.get(yearsRangeDifferenceAgainst);

      let differenceAgainstValue =
        diff ??
        parseInt(yearsRangeDifferenceAgainstQuoteValue) + parseInt(yearsRangeDifference);

      if (
        !isNullOrUndefined(differenceAgainstValue) &&
        differenceAgainstValue !== "" &&
        !isNaN(differenceAgainstValue)
      ) {
        yearsRangeDifference = Math.abs(currentYear - differenceAgainstValue);
      } else if (!isNullOrUndefined(defaultYearsRange)) {
        yearsRangeDifference = defaultYearsRange;
      }
    }

    //hooks

    let [purchaseDateDisabled, setPurchaseDateDisabled] = useState(false);

    const windowSize = useWindowSize();
    //adds DD/MM/YYYY as placeholder on select date on mobile
    const mustAddPlaceholder =
      windowSize.width < 991 && attributes?.usePlaceholder === true;

    const { monthSelectOptions, yearSelectOptions } = useMemo(() => {
      let months = [];
      let years = [];

      let startYearValue;
      let endYearValue;

      if (name === controlNames.bikeDateLicenceObtainedCar) {
        startYearValue = parseInt(dayjs().format("YYYY"));
        endYearValue = parseInt(
          dayjs(dobValue, "YYYY-MM-DD")
            .add(parseInt(yearsRangeDifference), "years")
            .format("YYYY")
        );
      } else {
        startYearValue = parseInt(
          dayjs().subtract(parseInt(maxYearDifference), "years").format("YYYY")
        );
        endYearValue = parseInt(
          dayjs().subtract(parseInt(yearsRangeDifference), "years").format("YYYY")
        );
      }

      for (let i = 1; i <= 12; i++)
        months.push({ value: i < 10 ? `0${i}` : `${i}`, label: `${i}` });
      if (endYearValue < startYearValue) {
        for (let i = startYearValue; i >= endYearValue; i--)
          years.push({ value: `${i}`, label: `${i}` });
      } else {
        for (let i = startYearValue; i <= endYearValue; i++)
          years.push({ value: `${i}`, label: `${i}` });
      }

      const defaultPlaceholder = { value: "", label: "" };
      const monthsPlaceholder = mustAddPlaceholder
        ? { value: "", label: "MM" }
        : defaultPlaceholder;
      const yearsPlaceholder = mustAddPlaceholder
        ? { value: "", label: "YYYY" }
        : defaultPlaceholder;

      return {
        monthSelectOptions: [monthsPlaceholder].concat(months),
        yearSelectOptions: [yearsPlaceholder].concat(years),
      };
    }, [mustAddPlaceholder, maxYearDifference, yearsRangeDifference]);

    const handleValidationForControl = (year, month, day) => {
      dateChanged = checkDateChanged([year, month, day]);
      let value = `${year}-${month}-${day}`;
      handleDateChange(name, value, dateChanged);
      controlsLogicHelper.ManipulateControlValuesOnChange(name, value);
    };

    let subControls = {};
    children.map((item) => {
      let controlName = item.Name;
      let control = (
        <ControlsFactory
          model={item}
          key={item.Name}
          setDisableControl={(ev) => {
            const liveValidation =
              AppStore.getUserJourneySettings()["generalSettings"] &&
              AppStore.getUserJourneySettings()["generalSettings"]["liveValidation"];

            if (ev.currentTarget.checked) {
              setPurchaseDateDisabled(true);
              handleDateChange(name, `${currentDate.format("YYYY-MM-DD")}`, dateChanged);
              if (liveValidation) {
                AppStore.setHasChanged(name, true);
              }
            } else {
              dateChanged = false;
              setPurchaseDateDisabled(false);
              const day = getDayForYearAndMonth(
                currentDate.get("year"),
                (currentDate.get("month") + 1).toString().padStart(2, "0")
              );
              handleDateChange(name, `YYYY-MM-${day}`, dateChanged);
              if (liveValidation) {
                AppStore.setHasChanged(name, false);
              }
            }
          }}
        />
      );
      let vehicleNotPurchedValue = AppStore.getControlByName("vehicle-not-purchased");
      if (!isNullOrUndefined(vehicleNotPurchedValue)) {
        purchaseDateDisabled =
          vehicleNotPurchedValue === "false" ||
          vehicleNotPurchedValue === false ||
          vehicleNotPurchedValue === ""
            ? false
            : true;
      } else {
        purchaseDateDisabled = false;
      }
      subControls[controlName] = control;
    });

    const controlComponent = (
      <React.Fragment key={`controlkey-${name}`}>
        <div className="field-date-part field-date-part-month d-inline-block col-lg-6 col-md-6 col-sm-3 col-4">
          <label className="col-lg-5 col-md-5 d-none d-md-inline-block d-lg-inline-block">
            Month
          </label>
          <select
            className="form-control d-inline-block col-lg-6 col-md-6 col-sm-12 col-12"
            id={`${name}[month]`}
            data-inner-item={`${name}-month`}
            data-testid={`test-${name}-month`}
            name={`${name}-month`}
            value={month}
            onChange={(event) => {
              if (year === "invalid") {
                year = "YYYY";
              }

              const day = getDayForYearAndMonth(
                year === "YYYY" ? currentDate.get("year") : year,
                event.target.value
              );

              handleValidationForControl(year, event.target.value, day);
              handleInteraction(event);
            }}
            disabled={purchaseDateDisabled ? true : null}>
            {monthSelectOptions.map((item) => (
              <option value={item.value} key={name + "_month_" + item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="field-date-part field-date-part-year d-inline-block col-lg-6 col-md-6 col-sm-3 col-4">
          <label className="col-lg-4 col-md-4 d-none d-md-inline-block d-lg-inline-block">
            Year
          </label>
          <select
            className="form-control d-inline-block col-lg-8 col-md-7 col-sm-12 col-12"
            id={`${name}[year]`}
            name={`${name}-year`}
            data-inner-item={`${name}-year`}
            data-testid={`test-${name}-year`}
            value={year}
            onChange={(event) => {
              const day = getDayForYearAndMonth(
                event.target.value,
                month === "MM"
                  ? (currentDate.get("month") + 1).toString().padStart(2, "0")
                  : month
              );

              handleValidationForControl(event.target.value, month, day);
              handleInteraction(event);

              if (!dateChanged) {
                AppStore.setHasChanged(name, false);
              } else {
                AppStore.setHasChanged(name, true);
              }
            }}
            disabled={purchaseDateDisabled ? true : null}>
            {yearSelectOptions.map((item) => (
              <option value={item.value} key={name + "_year_" + item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        {subControl && (
          <ControlsFactory model={subControl} validation={subControl.validation} />
        )}
      </React.Fragment>
    );

    const componentMapping = {
      label: (
        <Label
          key={`labelkey-${name}`}
          id={id}
          label={label}
          validation={validation ? validation : ""}
          forInput={name}
        />
      ),
      tooltip: (
        <Tooltip
          key={`tooltipkey-${name}`}
          helpMessages={helpMessages != null ? helpMessages.help : ""}
          controlName={name}
        />
      ),
      info: (
        <Info
          key={`infokey-${name}`}
          infoMessages={infoMessages != null ? infoMessages.info : ""}
        />
      ),
      error: (
        <Error
          key={`errorkey-${name}`}
          validation={validation ? validation : ""}
          className={`next-line`}
        />
      ),
      control: controlComponent,
    };

    const groupClasses = {
      "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
      "form-group": `col-lg-5 col-md-5 col-sm-12 col-12`,
    };

    return (
      <React.Fragment>
        <div
          className={`form-group form-group-container form-group-${name} ${customFormGroupClass}`}>
          <div id={`row-${name}`} className={`row row-${errorClass} `}>
            <ControlRowFactory
              controlName={name}
              groupClasses={groupClasses}
              componentMappings={componentMapping}
            />
          </div>
        </div>
        {[subControls["vehicle-not-purchased"]]}
      </React.Fragment>
    );
  }
);

export default DateMonthYear;
