import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import ControlsFactory from "../../../factories/ControlsFactory";
import accessibilityHelper from "../../../helpers/accessibilityHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import SlideToggleContent from "../../controls/SlideToggleContent.Component";
import Error from "../../controls/StaticControls/Error.Component";
import Info from "../../controls/StaticControls/Info.Component";
import Label from "../../controls/StaticControls/Label.Component";
import Tooltip from "../../controls/StaticControls/Tooltip.Component";
import TextArea from "../StaticControls/TextArea.Component";

const MarketingStatement = ({
  id,
  name,
  label,
  controlValuesList,
  handleChange,
  currentValue,
  children,
  defaultValue,
  infoMessages,
  extraInfo,
  validation,
  attributes,
  helpMessages,
}) => {
  let subControls = {};
  children.map((item) => {
    let controlName = item.Name;
    let control = <ControlsFactory model={item} key={item.Name} />;
    subControls[controlName] = control;
  });

  const errorClass = validation && validation.isValid === false ? "error" : "";
  const versionType =
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls) &&
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls.customRadio)
      ? AppStore.getUserJourneySettings().customControls.customRadio
      : "";
  const versionTypeStyle = versionType !== "" ? "radio-version-" + versionType : "";
  let [isVisible, setIsVisible] = useState(true);
  let [isChecked, setIsChecked] = useState("");
  let proposerEmailControlName = !isNullOrUndefined(attributes.proposerEmail)
    ? attributes.proposerEmail
    : "";
  let proposerPhoneControlName = !isNullOrUndefined(attributes.proposerPhone)
    ? attributes.proposerPhone
    : "";
  let caption = !isNullOrUndefined(attributes.caption) ? attributes.caption : "";
  let showOptions = !isNullOrUndefined(attributes.showOptions)
    ? attributes.showOptions
    : true;
  const desktopTooltipToggle =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.desktopTooltipToggle)
      ? attributes.desktopTooltipToggle
      : false;
  const autoFillOption = !isNullOrUndefined(attributes.autoFill)
    ? attributes.autoFill
    : false;
  const [unsubscribeList] = useState(
    AppStore.getDataSourceByControlName("lightbox-options")
  );

  useEffect(() => {
    if (autoFillOption) {
      setIsChecked(currentValue);
      AppStore.setFormData({ [name]: currentValue });
    } else {
      AppStore.setFormData({ [name]: isChecked });
    }

    setIsVisible(false);
  }, []);

  const changeHandler = (ev) => {
    AppStore.setUnsubscribeData({
      ["proposerPhone"]: AppStore.getControlByName(proposerPhoneControlName),
    });
    AppStore.setUnsubscribeData({
      ["proposerEmail"]: AppStore.getControlByName(proposerEmailControlName),
    });

    if (ev.target.value === "yes") {
      setIsVisible(false);
      AppStore.setUnsubscribeData({
        ["unsubscriptionList"]: unsubscribeList.map((item) => ({
          ...item,
          Ticked: true,
        })),
      });
    } else {
      setIsVisible(true);

      //This setting will hide the options and will set the list to send them as unticked to unsubscribe
      if (!showOptions) {
        let unsubscribeList = AppStore.getDataSourceByControlName("lightbox-options");
        if (!isNullOrUndefined(unsubscribeList));
        {
          AppStore.setUnsubscribeData({
            ["unsubscriptionList"]: unsubscribeList.map((item) => ({
              ...item,
              Ticked: false,
            })),
          });
        }
      }
    }
    handleChange(ev);
    setIsChecked(ev.target.value);
  };

  const controlComponent = (
    <React.Fragment key={`controlkey-${name}`}>
      {controlValuesList.map((item) => (
        <div
          key={name + "_" + item.Key}
          className={`form-check form-check-inline ${versionTypeStyle} ${
            currentValue === item.Key ? "checked" : ""
          }`}>
          <input
            id={name + "_" + item.Key}
            data-testid={`test-${name}_${item.Key}`}
            className="form-check-input"
            type="radio"
            name={name}
            value={item.Key}
            onChange={changeHandler}
            checked={isChecked === item.Key ? "checked" : ""}
          />
          <label
            className="form-check-label"
            htmlFor={name + "_" + item.Key}
            onKeyDown={(event) =>
              accessibilityHelper.replicateClickAction(event, event.target.htmlFor)
            }
            tabIndex="0">
            {item.Value}
          </label>
        </div>
      ))}
    </React.Fragment>
  );

  const slideContent = (
    <div className={`form-group form-group-container slidedown-toggle slidedown-${name}`}>
      <SlideToggleContent isVisible={isVisible}>
        <div className={`slidedown-toggle-caption p-0 pt-2 px-3`}>
          <label>
            {caption !== ""
              ? reactStringReplace(caption, "<br/>", (match, i) => <br key={i} />)
              : "Please select the type of communications you’d like to receive:"}
          </label>
        </div>
        <div className={`slidedown-toggle-content d-flex flex-wrap`}>
          {subControls["lightbox-options"]}
        </div>
      </SlideToggleContent>
    </div>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        className={`qs-label qs-label-${name} d-inline-block col-lg-12 col-md-11 col-sm-11 col-11 pr-1`}
        id={id}
        label={label}
        validation={!!isChecked === validation?.isValid ? validation : ""}
        forInput={name}
        defaultValue={defaultValue}
      />
    ),
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}
        <Info
          key={`infokey-${name}`}
          infoMessages={infoMessages != null ? infoMessages.info : ""}
        />
      </>
    ),
    error: (
      <Error
        key={`errorkey-${name}`}
        validation={validation ? validation : ""}
        className="next-line"
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-sm-12 col-12 col-lg-8 col-md-9",
    "form-group": `col-sm-12 col-12 d-inline-block col-lg-4 col-md-3`,
  };

  return (
    <>
      <div
        className={`form-group form-group-container form-group-${name} ${
          versionType === "button" ? "custom-group" : ""
        }`}>
        <div id={`row-${name}`} className={`row row-${errorClass} `}>
          <ControlRowFactory
            controlName={name}
            groupClasses={groupClasses}
            componentMappings={componentMapping}
          />
        </div>
      </div>
      {showOptions && slideContent}
    </>
  );
};

MarketingStatement.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MarketingStatement;
