import { Cookies } from "@constants/csProviderConstants";
import queryString from "query-string";
import { cookies } from "./cookies";

/**
 * @typedef {Object} FirstInteractionIndividInfo
 * @property {string} product
 * @property {string} [refId]
 * @property {Boolean} value
 */

/**
 * @typedef {Object} FirstInteractionIndividParams
 * @property {string} [product] product value to account. By default takes product from import.meta.env
 * @property {string} [refId] ref id value to account. By default takes value from query param `refid`
 */

/**
 * Obtain first interaction cookie value.
 * If cookie value was malformed, this function will return `undefined`
 *
 * @return {FirstInteractionIndividInfo[] | undefined}
 */
const parseFiCookieValue = () => {
  const fiRaw = cookies.get(Cookies.FirstInteractionCookie);

  if (!fiRaw) return;

  try {
    const fi = JSON.parse(fiRaw);

    //? validate cookie value format
    if (
      fi &&
      Array.isArray(fi) &&
      fi.every((item) => item.value === true || item.value === false)
    ) {
      return fi;
    }
  } catch (_err) {
    //
  }
};

/**
 * @param {FirstInteractionIndividParams} [options]
 * @returns {FirstInteractionIndividParams}
 */
export const inferIndividParams = (options = {}) => {
  let { product, refId } = options;

  product = product ?? import.meta.env.VITE_PRODUCT?.toLowerCase();

  if (!refId) {
    try {
      const parsedQueryStr = queryString.parse(window.location.search);
      refId = parsedQueryStr.refid;
    } catch (_err) {
      //
    }
  }

  return { product, refId };
};

/**
 * Get parsed First Interaction cookie based on product and refid
 *
 * @param {FirstInteractionIndividParams} [options]
 * @return {Boolean | undefined}
 */
export const getFirstInteraction = (options) => {
  const { product, refId } = inferIndividParams(options);

  return parseFiCookieValue()?.find(
    (item) => item.product === product && item.refId === refId
  )?.value;
};

/**
 * Set First Interaction cookie based on product and refid
 *
 * @param {boolean} value First Interaction value
 * @param {FirstInteractionIndividParams} [options]
 * @param {import("./cookies").CookieAttributes} [cookieAttr]
 */
export const setFirstInteraction = (value, options, cookieAttr) => {
  const { product, refId } = inferIndividParams(options);

  const existingFi = parseFiCookieValue();
  const newFi = existingFi ? [...existingFi] : [];

  const individIdx = newFi.findIndex(
    (item) => item.product === product && item.refId === refId
  );

  if (individIdx >= 0) {
    newFi[individIdx] = {
      ...newFi[individIdx],
      value,
    };
  } else {
    newFi.push({
      product,
      refId,
      value,
    });
  }

  cookies.set(Cookies.FirstInteractionCookie, JSON.stringify(newFi), cookieAttr);
};
