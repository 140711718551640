import controlNames from "@constants/controlNames";
import dateParser from "@helpers/dateParser";
import AppStore from "@store/AppStore";
import dayjs from "dayjs";

const visibleControls = [
  controlNames.incomeLifeCrossSell,
  controlNames.incomeLifeCrossSellMessageBox,
  controlNames.otherInsuranceSection,
];

const handleVisibility = (shouldShow = true) => {
  const dateOfBirth = AppStore.getControlByName(controlNames.dateOfBirth);
  const age = parseInt(dateParser.getDateDiffInYears(dateOfBirth, dayjs()));

  if (age >= 18 && age <= 65) {
    AppStore.changeVisibilityControls(visibleControls, shouldShow);
  } else {
    AppStore.changeVisibilityControls(visibleControls, false);
  }
};

function manipulateControlsPreRender(controlName) {
  if (controlName === controlNames.incomeLifeCrossSell) {
    const bikePurchased = !Boolean(
      AppStore.getControlByName(controlNames.vehicleNotPurchased)
    );

    AppStore.setFormData({ [controlNames.incomeLifeCrossSell]: "no" });
    handleVisibility(bikePurchased);
  }
}

function manipulateControlsOnChange(controlName, controlValue) {
  if (controlName === controlNames.vehicleNotPurchased) {
    if (controlValue === true) {
      AppStore.setFormData({ [controlNames.incomeLifeCrossSell]: "no" });
      handleVisibility(false);
    } else {
      handleVisibility(true);
    }
  }
}

export default {
  manipulateControlsPreRender,
  manipulateControlsOnChange,
};
