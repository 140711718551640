import {
  SetPreviousVehicleDependentControlsVisibility,
  SetPreviousVehicleVisibility,
} from "@helpers/controlLogic/previousVehicleVisibilityHelper";
import controlsLogicHelper from "@helpers/controlsLogicHelper";
import DateParser from "@helpers/dateParser";
import AppStore from "@store/AppStore";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import accessibilityHelper from "../../helpers/accessibilityHelper";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";
import CheckboxLabel from "./CheckboxLabel.Component";
const CustomCheckbox = ({
  id,
  name,
  label,
  handleCheckboxChange,
  handleMarketingCheck,
  handleSetDisabledChange,
  handleInteraction,
  currentValue,
  helpMessages,
  infoMessages,
  validation,
  attributes,
}) => {
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const [isChecked, setIsChecked] = useState(
    currentValue === "" ? false : currentValue === true || currentValue === "true"
  );

  const noPrecedingText = attributes.noPrecedingText || false;
  const precedingText = attributes.precedingText || "Or";

  useEffect(() => {
    handleCheckboxChange(name, isChecked);
  }, []);

  useEffect(() => {
    if (name === "vehicle-not-purchased") {
      const datePurchased = AppStore.getControlByName("purchase-date");
      const diffInDays = DateParser.getDateDiffInDays(datePurchased, dayjs());

      if (isChecked === false && !DateParser.isDateValid(datePurchased)) {
        SetPreviousVehicleVisibility(false);
        SetPreviousVehicleDependentControlsVisibility(false);
      } else if (
        isChecked === true ||
        (isChecked === false &&
          DateParser.isDateValid(datePurchased) &&
          diffInDays <= 365 &&
          diffInDays >= 0)
      ) {
        SetPreviousVehicleVisibility(true);
      }
    }
  }, [isChecked]);

  const changeHandler = (event) => {
    if (handleMarketingCheck) handleMarketingCheck(event);
    handleCheckboxChange(name, event.currentTarget.checked);
    setIsChecked(event.currentTarget.checked);
    handleSetDisabledChange(event);
    handleInteraction(event);
    controlsLogicHelper.ManipulateControlValuesOnChange(
      name,
      event.currentTarget.checked
    );
  };

  const controlComponent = (
    <>
      {!noPrecedingText && <span className="preceding-text">{precedingText}</span>}
      <div
        key={`controlkey-${name} ${attributes.subControl ? "subControl" : ""}`}
        className="custom-checkbox-row">
        <CheckboxLabel
          parentId={id}
          parentName={name}
          label={attributes.checkboxLabel}
          isChecked={isChecked}
          version={3}
          keyDownHandler={(event) =>
            accessibilityHelper.replicateClickAction(event, name)
          }></CheckboxLabel>
        <input
          id={name}
          data-testid={`test-${name}`}
          className="form-check-input"
          type="checkbox"
          name={name}
          onChange={changeHandler}
          checked={isChecked}
        />
      </div>
    </>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        validation={validation ? validation : ""}
        forInput={name}
      />
    ),
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <Info
        key={`infokey-${name}`}
        infoMessages={infoMessages != null ? infoMessages.info : ""}
      />
    ),
    error: <Error key={`errorkey-${name}`} validation={validation ? validation : ""} />,
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  return (
    <div className={`form-group form-group-container form-group-${name}`}>
      <div className={`row row-${errorClass} `}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CustomCheckbox;
