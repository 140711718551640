import sessionService from "@services/sessionService";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { vistatsV1Call } from "../../../helpers/vistatsV1Helper";
import ClientLogging from "../../../providers/loggingProvider";
import http from "../../../services/httpService";
import AppStore from "../../../store/AppStore";
import { preserveQueryStringParams } from "../../AppLink";
import PromptPopup from "../PromptPopup.Component";
import AdUnit from "./AdUnit.Component";

const LightBoxPopup = observer(({ currentPageIndex, currentPage, slotName }) => {
  const lightboxConfig = AppStore.getUserJourneySettings()?.lightbox;
  const product = import.meta.env.VITE_PRODUCT?.toLowerCase() || "";
  const lightboxVersion = lightboxConfig?.version || "v1";
  const message =
    lightboxConfig?.message ||
    `You could be missing out on finding your cheapest ${product} insurance quote`;
  const title = lightboxConfig?.title || "Leaving so soon?";

  const blockedPages = lightboxConfig?.blockedPages || [
    "postcodeverificationdefault",
    "default",
    "renewalassumptions",
    "privacypolicy",
    "termsconditions",
    "quotesummary",
    "unsubscribe",
    "postcodeverification",
    "buy",
    "applicationerror",
    "nosession",
    "pagenotfound",
    "yourquotes",
  ];

  const noSessionTimerRef = useRef(null);
  const secondaryTimerRef = useRef(null);

  const secondaryIdleTimeout = (lightboxConfig?.timeoutMinsSecondary || 20) * 1000 * 60;
  const mainIdleTimeout = (lightboxConfig?.timeoutMinsPrimary || 20) * 1000 * 60;

  let isVisible = AppStore.userInteractionState.get("isLightboxVisible"); //Will also set header "on-top" when true
  let isEnabled =
    lightboxConfig?.isEnabled !== false &&
    AppStore.userInteractionState.get("isLightboxEnabledOnPage");

  useIdleTimer({
    disabled: !isEnabled,
    timeout: mainIdleTimeout,
    debounce: 250,
    onIdle: () => {
      if (isEnabled) {
        AppStore.setUserInteractionState({ ["isLightboxVisible"]: true });

        vistatsV1Call(
          `${slotName}`,
          currentPage.url,
          () => `Page=${currentPage.url}&Interaction=Open`,
          () => `${slotName}`
        );
      }
    },
  });

  const initiateNoSessionTimer = () => {
    //TODO: get session duration from config and calculate this timer based on that
    noSessionTimerRef.current = setTimeout(() => {
      window.location.replace(
        preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/nosession`)
      );
      sessionService.removeSessionToken();
    }, 1000 * 60 * 25);
  };

  const initiateSecondaryTimer = () => {
    secondaryTimerRef.current = setTimeout(() => {
      const endpoint = `/${import.meta.env.VITE_QUOTES_API_ENDPOINT}/failedQuote`;
      http
        .post(endpoint, {
          appHost: import.meta.env.VITE_BASE_URL,
          appRoute: import.meta.env.VITE_SITE_ROUTE,
          recallSuffix: `/default/`,
          collectedQuoteData: AppStore.getFormData(),
        })
        .then(() => {
          initiateNoSessionTimer();
        })
        .catch((error) => {
          ClientLogging.logError(error, "Failed Quote");
        });
    }, secondaryIdleTimeout);
  };

  const handleModalClose = () => {
    AppStore.setUserInteractionState({ ["isLightboxVisible"]: false });

    if (secondaryTimerRef.current) {
      clearTimeout(secondaryTimerRef.current);
    }
    if (noSessionTimerRef.current) {
      clearTimeout(noSessionTimerRef.current);
    }

    vistatsV1Call(
      `${slotName}`,
      currentPage.url,
      () => `Page=${currentPage.url}&Interaction=Continue`,
      () => `${slotName}`
    );
  };

  const resetQuote = () => {
    AppStore.clearFormData();
    AppStore.setUserInteractionState({ ["isLightboxVisible"]: false });

    if (secondaryTimerRef.current) {
      clearTimeout(secondaryTimerRef.current);
    }
    if (noSessionTimerRef.current) {
      clearTimeout(noSessionTimerRef.current);
    }

    vistatsV1Call(
      `${slotName}`,
      currentPage.url,
      () => `Page=${currentPage.url}&Interaction=ResetQuote`,
      () => `${slotName}`
    );

    window.location.replace(
      preserveQueryStringParams(
        `${import.meta.env.VITE_SITE_ROUTE}/${
          AppStore.getUserJourneySettings()?.generalSettings?.entryPoint ?? ""
        }`
      )
    );
  };

    useEffect(() => {
        if (!blockedPages.includes(AppStore.getPages()[currentPageIndex]?.url)) {
            AppStore.setUserInteractionState({["isLightboxEnabledOnPage"]: true });
        } else {
          AppStore.setUserInteractionState({ ["isLightboxEnabledOnPage"]: false });
        }
    }, [currentPageIndex]);

  useEffect(() => {
    if (isVisible && isEnabled) {
      initiateSecondaryTimer();
    }
  }, [isVisible]);

  return isEnabled ? (
    <>
      <PromptPopup
        isOpen={isVisible}
        id="lightbox-ad"
        handleClose={handleModalClose}
        hasCloseBtn
        closeOnOutsideClick={false}
        text={message}
        title={title}
        actionBtn={{
          id: "lightbox-continue-btn",
          name: "lightbox-continue-btn",
          label: "Continue my quote",
          icon: lightboxVersion === "v2" ? "arrow-right" : null,
          onClick: handleModalClose,
        }}
        cancelBtn={{
          id: "lightbox-reset-btn",
          name: "lightbox-reset-btn",
          label: "Start my quote again",
          icon: lightboxVersion === "v2" ? "arrow-left" : null,
          onClick: resetQuote,
        }}
        customClassMapping={{
          container: "lightbox-container overlay",
          main: "lightbox-main",
          head: "lightbox-head",
          closeBtn: "lightbox-btn-close close",
          closeBtnIcon: "lightbox-btn-close-icon",
          content: "content",
          title: "lightbox-title",
          text: "lightbox-text",
          footer: "lightbox-footer",
          actionBtn: "lightbox-btn continue",
          cancelBtn: "lightbox-btn reset",
        }}
      />
      {isVisible && (
        <div className={"dfp-qhb-popup d-flex flex-column overlay is-active"}>
          <div className="lightbox-ad">
            <AdUnit
              currentPage={AppStore.pageComponents[currentPageIndex]}
              slotName="LIGHTBOX_DEFAULT"
              cssClass="mpu-one"
            />
          </div>
        </div>
      )}
    </>
  ) : null;
});

export default LightBoxPopup;
