import { getFirstInteraction, setFirstInteraction } from "@helpers/first-interaction";
import googleAnalyticsHelper from "@helpers/googleAnalyticsHelper";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { preserveQueryStringParams } from "../components/AppLink";
import { Actions, Categories, Messages } from "../constants";
import controlsLogicHelper from "../helpers/controlsLogicHelper";
import helpers from "../helpers/destructure";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import ClickStreamProvider from "../providers/ClickStreamProvider";
import ClientLogging from "../providers/loggingProvider";
import FormValidator from "../services/validationService";
import AppStore from "../store/AppStore";
import AppStoreActions from "../store/AppStoreActions";
import {
  triggerQuestionSetStarted,
  triggerSetCookieForQuestionSetFinish,
  vistatsLoad,
} from "../tracking/vistats";
import Controls from "./imports/controls";

const ControlsFactory = (props) => {
  const history = useHistory();
  const [state, setState] = useState({ wasScrolledAfterValidation: false });
  const [validator] = useState(() => new FormValidator());
  const controlName = props.model.Name;
  const [initialControlValue] = useState(() =>
    controlsLogicHelper.HandleControlsPreRender(props.model.Name)
  );
  const shouldRevalidate = AppStore.getShouldRevalidate(controlName);
  const shouldClearValidation = AppStore.getShouldClearValidation(controlName);

  let controlValue = initialControlValue;

  controlValue = isNullOrUndefined(controlValue)
    ? AppStore.getControlByName(controlName)
    : controlValue;

  if (isNullOrUndefined(controlValue)) {
    if (isNullOrUndefined(props.model.DefaultValue)) {
      controlValue = "";
    } else {
      controlValue = props.model.DefaultValue;
    }
  }

  if (
    props.model.Type !== undefined &&
    AppStore.getVisibilityControls().get(controlName) !== false
  ) {
    AppStore.setFormData({
      [controlName]: controlValue,
    });
  }

  useEffect(() => {
    controlsLogicHelper.HandleControlsPostRender(props.model.Name, controlValue);
    googleAnalyticsHelper.pushTimeToInteractive(controlName);
  }, []);

  useEffect(() => {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(duration);

    try {
      vistatsLoad(true, dayjs);
      triggerSetCookieForQuestionSetFinish(dayjs);
      triggerQuestionSetStarted(true, dayjs);

      if (controlValue === "") {
        AppStore.setHasChanged(controlName, false);
      } else {
        const result = validator.validateField({
          [controlName]: controlValue,
        });

        if (result.isValid) {
          AppStore.setHasChanged(controlName, true);
        } else {
          AppStore.setHasChanged(controlName, false);
        }
      }

      AppStoreActions.executeActionsOnLoad(
        controlName,
        AppStore.getControlByName(controlName)
      );
    } catch (e) {
      ClientLogging.logError("ControlsFactory->Vistats events attach", e);
    }
  }, [AppStore.areValidationRulesLoaded]);

  useEffect(() => {
    try {
      vistatsLoad(true, dayjs);
      triggerSetCookieForQuestionSetFinish(dayjs);
      triggerQuestionSetStarted(true, dayjs);
    } catch (e) {
      ClientLogging.logError("ControlsFactory->Vistats events attach", e);
    }
  });

  useEffect(() => {
    if (shouldClearValidation) {
      handleResetValidation([controlName]);
      AppStore.setHasChanged(controlName, false);
      AppStore.removeShouldClearValidation(controlName);
    }
  }, [shouldClearValidation]);

  useEffect(() => {
    if (shouldRevalidate) {
      if (
        !isNullOrUndefined(controlValue) &&
        controlValue !== "" &&
        controlValue !== props.model.DefaultValue
      ) {
        validateControl({ target: { name: controlName, value: controlValue } });
        AppStore.removeShouldRevalidate(controlName);
      } else {
        AppStore.setHasChanged(controlName, false);
      }
    }
  }, [shouldRevalidate]);

  const handleInteraction = (event) => {
    if (event.type === "change" || event.type === "mousedown") {
      AppStore.setUserInteractionState({
        lastControlInteracted: props.model,
      });
    }
  };

  const handleChange = (event, checkValidation = false) => {
    let liveValidationEnabled = AppStore.liveValidation;
    const invalidType =
      event.target.type &&
      event.target.type !== "text" &&
      event.target.type !== "tel" &&
      event.target.type !== "email"
        ? true
        : false;

    const validate = (event, holdValidation = false) => {
      // holdValidation is applied by versions using live validation - when the user is changing a text field we remove any error messages,
      // and revalidate when the user leaves the field
      if (holdValidation) {
        // remove error messages (set field to be valid)
        AppStore.setHasChanged(controlName, false);
        handleResetValidation([event.target.name]);
      } else {
        // otherwise, validate field as normal
        validateControl(event);
      }
    };

    if (checkValidation) {
      validate(event);
    } else {
      AppStore.setFormData({ [event.target.name]: event.target.value });
      AppStoreActions.executeActionsOnChange(event.target.name, event.target.value);
    }

    // if liveValidation has been set to true in the userJourneySettings, validate the change event
    if (liveValidationEnabled) {
      // if the changed input is one of the following, validate as normal
      if (["select", "select-one", "radio", "checkbox"].includes(event.target.type)) {
        validate(event);

        // set the hasChanged value for the changed control to be true - this is used for live validation
        // this only applies to dropdown and radio controls, and not the proposer-postcode control (completing this field doesn't 'complete' the whole control.)
        if (
          event.target.name !== "proposer-postcode" &&
          ["select", "select-one", "radio"].includes(event.target.type)
        ) {
          AppStore.setHasChanged(event.target.name, true);
        }
      }
      // if the input is a text field, set the field to be valid (we only check for validation onBlur)
      else {
        AppStore.setHasChanged(event.target.name, false); // remove hasChanged status (we only re-check for this onBlur)
        validate(event, true); // the second parameter is responsible for setting validity to true.
      }
    }

    if (invalidType) {
      sendInteractionEvent(event.target.name, Messages.EmptyMessage);
    }
  };

  const handleDateChange = (controlName, value, dateChanged, checkValidation = false) => {
    // setHasChanged to true is unconditional - in classic we want to check if uk-resident-since has changed
    // so we can decide whether to change the uk-resident-since field based on date-of-birth
    AppStore.setHasChanged(controlName, true);

    if (checkValidation || AppStore.liveValidation) {
      if (dateChanged) {
        validateControl({ target: { name: controlName, value: value } });
      } else {
        AppStore.setHasChanged(controlName, false);
        handleResetValidation([controlName]);
      }
    }
    AppStore.setFormData({ [controlName]: value });

    sendInteractionEvent(controlName, Messages.EmptyMessage);
  };

  const handleCheckboxChange = (controlName, value) => {
    if (AppStore.liveValidation) {
      validateControl({ target: { name: controlName, value: value } });
    }
    AppStore.setFormData({ [controlName]: value });
    sendInteractionEvent(controlName, Messages.EmptyMessage);
  };

  const handleSetDisabledChange = (ev) => {
    if (props.setDisableControl !== undefined) {
      props.setDisableControl(ev);
    }
  };

  const handleBlur = (ev, checkValidation = true, hasExcludedRules = false) => {
    let liveValidationEnabled = AppStore.liveValidation;
    let result;

    if (checkValidation && liveValidationEnabled) {
      // validateRules avoids certain rules based on the validation method supplied (handleBlur here)
      if (hasExcludedRules) {
        result = validator.validateRules(
          { [ev.target.name]: ev.target.value },
          "handleBlur"
        );
      } else {
        result = validator.validateField({ [ev.target.name]: ev.target.value });
      }

      validateControl(ev, result);
      AppStore.setHasChanged(ev.target.name, true);
    }

    AppStore.setFormData({ [ev.target.name]: ev.target.value });
    sendInteractionEvent(ev.target.name, Messages.EmptyMessage);
  };

  const handleClick = (ev) => {
    const path = ev.target.getAttribute("redirectto");
    if (path === "yourquotes") {
      sendInteractionEvent(ev.target.name, Messages.FinishedQuote, Actions.FinishedQuote);
    } else {
      sendInteractionEvent(
        ev.target.name,
        Messages.SwitchPage + path,
        Actions.SwitchPage
      );
    }
    history.push(preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/${path}`));
  };

  const handleRedirectCustom = (ev) => {
    const path = ev.target.getAttribute("redirectto");
    const queryString = ev.target.getAttribute("querystring");
    history.push(
      preserveQueryStringParams({
        pathname: `/${path}`,
        search: `${queryString}`,
      })
    );
  };

  const handleOccupationList = (autocomplete, select) => {
    AppStore.setFormData({ [autocomplete]: false }, { [select]: true });
  };

  const sendInteractionEvent = (controlName, value, action = Actions.ContinueQuote) => {
    if (getFirstInteraction() === true) {
      let components = AppStore.getPageComponents();
      let firstPageControl = components[0]?.components[0]?.Controls?.find(
        (c) => c.Name === controlName
      );
      if (firstPageControl !== undefined) {
        ClickStreamProvider.handleClickStreamEvent(
          Categories.QuestionSetCompletion,
          Actions.StartQuote,
          controlName,
          value
        );
        setFirstInteraction(false);
      }
    } else if (AppStore.getSendFullQuoteData() === "true") {
      ClickStreamProvider.handleClickStreamEvent(
        Categories.QuestionSetCompletion,
        action,
        controlName,
        value
      );
    }
  };

  // handleResetValidation has been created for its use in live validation.
  // For some components we want to remove / reset their error status so when the control is
  // re-presented to the user, it is as though it hasn't yet been interacted with.
  const handleResetValidation = (controlNames, options = {}) => {
    if (!controlNames) return;

    const { isValid, resetPageValidation = true } = options;

    if (props.updateValidator && resetPageValidation) {
      props.updateValidator(controlNames);
    }

    setState((state) => {
      const newState = { ...state };

      controlNames.forEach((controlName) => {
        delete newState[`validator-${controlName}`];
      });

      return newState;
    });

    controlNames.forEach((controlName) => {
      if (!isValid) AppStore.setHasChanged(controlName, false);
    });
  };

  const handleInvalidControl = (event, fieldData) => {
    setState((currentState) => {
      let wasScrolledAfterValidation = currentState.wasScrolledAfterValidation;

      if (!wasScrolledAfterValidation) {
        controlsLogicHelper.ScrollElementIntoView(`row-${event.target.name}`);
        wasScrolledAfterValidation = true;
      }

      return {
        ...currentState,
        wasScrolledAfterValidation,
        [fieldData.key]: fieldData.value,
      };
    });

    AppStore.setHasChanged(event.target.name, true);
    AppStore.invalidControls[event.target.name] = true;
  };

  // method defines the method used to prompt validation
  // rules are ignored in validateRules depending on the method
  const validateControl = (event, result = null, method = "handleBlur") => {
    result =
      result ??
      validator.validateRules({ [event.target.name]: event.target.value }, method);
    if (result.fields.length > 0) {
      const invalidFieldName = `validator-${event.target.name}`;
      let invalidFieldMessages = [];
      for (let i = 0; i < result.fields.length; i++) {
        let obj = helpers.destructure(result.fields[i]);
        if (obj.key === `validator-${event.target.name}`) {
          invalidFieldMessages.push(obj.value);
        }
      }
      if (invalidFieldMessages.length > 0) {
        const invalidField = { key: invalidFieldName, value: invalidFieldMessages };
        handleInvalidControl(event, invalidField);
      } else return;
    } else {
      handleResetValidation([event.target.name], { isValid: true });
      return true;
    }
    return false;
  };

  const getValidationState = (controlName) => {
    return state[`validator-${controlName}`];
  };

  const getRenderValidationState = (item) => {
    let validation = {
      isValid: true,
      messages: [],
    };

    const event = {
      target: {
        name: item.Name,
        value: AppStore.getFormData().get(item.Name),
      },
    };

    if (getValidationState(props.model.Name) !== undefined) {
      validation.isValid = getValidationState(props.model.Name).length === 0;
      getValidationState(props.model.Name).forEach((message) => {
        validation.messages.push(message);
      });
    } else {
      if (props.validationResult !== undefined && props.validationResult.length > 0) {
        props.validationResult.forEach((element) => {
          let el = helpers.destructure(element);

          let validatorList = item.Children.map((child) => `validator-${child.Name}`);
          validatorList.push(`validator-${item.Name}`);
          if (validatorList.includes(el.key)) {
            validation.isValid = false;
            validation.messages.push(el.value);
          }
        });
      }
    }

    return validation;
  };

  const handleInvisibleControl = (item) => {
    if (AppStore.liveValidation) {
      const validationState = getValidationState(item.Name);
      if (!isNullOrUndefined(validationState) && validationState.length > 0)
        handleResetValidation([item.Name]);
    }
  };

  const getControlValuesList = (item) => {
    //AppStore.codelists is data from /codelist endpoint
    if (AppStore.codelists[item.Name]) {
      if (item.Values[0])
        return item.Values[0].Values.concat(AppStore.codelists[item.Name]);

      return AppStore.codelists[item.Name];
    }

    return item.ControlValuesList;
  };

  const renderElement = (index, item) => {
    try {
      if (
        Controls[item.Type] !== undefined &&
        AppStore.getVisibilityControls().get(item.Name) !== false
      ) {
        const validation = getRenderValidationState(item);
        const ControlComponent = Controls[item.Type];

        return (
          <ControlComponent
            id={index}
            key={item.Name}
            label={item.Label}
            type={item.Type}
            name={item.Name}
            values={item.Values}
            currentValue={AppStore.getFormData().get(item.Name)}
            defaultValue={
              props.defaultValue !== undefined ? props.defaultValue : item.DefaultValue
            }
            controlValuesList={getControlValuesList(item)}
            handleBlur={handleBlur}
            handleInteraction={handleInteraction}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleValueChange={props.handleValueChange}
            handleCheckboxChange={handleCheckboxChange}
            handleChildChange={props.handleChildChange}
            handleChildClick={props.handleChildClick}
            handleChildBlur={props.handleChildBlur}
            handleChildFocus={props.handleChildFocus}
            handleModifyItem={props.handleModifyItem}
            handleDeleteItem={props.handleDeleteItem}
            handleSetDisabledChange={handleSetDisabledChange}
            handleResetValidation={handleResetValidation}
            validateControl={validateControl}
            handleOccupationList={handleOccupationList}
            helpMessages={item.HelpMessages}
            infoMessages={item.InfoMessages}
            extraInfo={item.ExtraInfo}
            handleClick={handleClick}
            handleRedirectCustom={handleRedirectCustom}
            validation={validation}
            attributes={item.Attributes}
            validationResult={props.validationResult}
            redirectto={props.redirectto}
            isResetted={props.isResetted}
            inheritCssClass={props.inheritCssClass}
            isDisabled={props.isDisabled}
            vehicleLookupNoResults={props.vehicleLookupNoResults}
            vehicleLookupPartialResults={props.vehicleLookupPartialResults}
            parentName={item.ParentName}
            additionalProps={props.additionalProps}
            logicalName={item.LogicalName}>
            {item.Children}
          </ControlComponent>
        );
      }

      if (item.Type === "AddressLookupInput") {
        handleInvisibleControl(item);
      }

      return null;
    } catch (ex) {
      ClientLogging.logError(ex, "render element");
      return null;
    }
  };

  try {
    if (props.model !== undefined) {
      return renderElement(props.index, props.model);
    }

    return null;
  } catch (ex) {
    ClientLogging.logError(ex, "controls render");
    return null;
  }
};

export default observer(ControlsFactory);
