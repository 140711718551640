import ClientLogging from "@providers/loggingProvider";
import dayjs from "dayjs";
import AppStore from "../store/AppStore";
import { cookies } from "./cookies";
import dateParser from "./dateParser";
import gtmDataLayer from "./gtmDataLayerHelper";
import { isNullOrUndefined } from "./isNullOrUndefined";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  pushEcommerceData: (data) => {
    gtmDataLayer.push({
      event: "ecommerce",
      transactionId: data.brokerReference,
      transactionAffiliation: data.position,
      transactionTotal: data.brokerPremium,
      transactionShipping: "40",
      transactionTax: "0",
      transactionProducts: [
        {
          id: data.brokerReference,
          sku: data.productSku,
          name: data.brokerName,
          category: data.cover,
          price: data.brokerPremium,
          quantity: "1",
        },
      ],
    });
  },
  pushCustomDimensions: () => {
    const userJourneySettings = AppStore.getUserJourneySettings();

    let exists = false;
    let customDimensionsDL = gtmDataLayer.checkEvent("custom_dimensions");
    if (customDimensionsDL.event === true) exists = true;

    let quoteAmended = false;
    let quoteAmendedValues = null;
    let quoteAmendedDL = gtmDataLayer.checkEvent("quote_amended");
    if (quoteAmendedDL.event === true) {
      quoteAmended = true;
      quoteAmendedValues = quoteAmendedDL.el;
    }

    quoteAmended =
      !isNullOrUndefined(quoteAmendedValues) &&
      quoteAmendedValues.quote_amended == "true";

    if (quoteAmended || !exists) {
      const customDimensions = userJourneySettings.custom_dimensions;

      customDimensions.county =
        AppStore.getControlByName("proposer-county") ?? "Not Found";
      const dob = AppStore.getControlByName("date-of-birth");
      customDimensions.dob = new Date(Date.parse(dob)).toLocaleString("en-GB", {
        timeZone: "UTC",
        dateStyle: "short",
      });
      customDimensions.occupation = AppStore.getDescriptionByCode(
        AppStore.getControlByName("occupation-abi-dropdown"),
        "occupation-abi-dropdown"
      );

      const propertyType = AppStore.getControlByName("property-types");
      switch (propertyType) {
        case "H":
          customDimensions.propertyType = AppStore.getDescriptionByCode(
            AppStore.getControlByName("house-types"),
            "house-types"
          );
          break;
        case "T":
          customDimensions.propertyType = AppStore.getDescriptionByCode(
            AppStore.getControlByName("town-property-types"),
            "town-property-types"
          );
          break;
        case "B":
          customDimensions.propertyType = AppStore.getDescriptionByCode(
            AppStore.getControlByName("flat-types"),
            "flat-types"
          );
          break;
        case "F":
          customDimensions.propertyType = AppStore.getDescriptionByCode(
            AppStore.getControlByName("bungalow-types"),
            "bungalow-types"
          );
          break;
        case "O":
          customDimensions.propertyType = AppStore.getDescriptionByCode(
            AppStore.getControlByName("other-property-types"),
            "other-property-types"
          );
          break;
        default:
          customDimensions.propertyType = "";
      }

      customDimensions.enginecc = AppStore.getControlByName("engine-cc");
      customDimensions.manufacturer =
        AppStore.getControlByLogicalName("vehicle-manufacturer");
      customDimensions.clientversion = import.meta.env.VITE_VERSION;

      gtmDataLayer.push({
        event: "custom-dimensions",
        custom_dimensions: customDimensions,
      });

      gtmDataLayer.push({ event: "quote-finished" });

      if (quoteAmended)
        gtmDataLayer.push({ event: "quote-amended", quote_amended: "false" });
    }
  },
  pushQuoteAmended: () => {
    gtmDataLayer.push({ event: "quote-amended", quote_amended: "true" });
  },
  pushQuoteFinished: () => {
    gtmDataLayer.push({ event: "quote-finished" });
  },
  pushPrepopStatus: () => {
    gtmDataLayer.push({ event: "Prepopulation", Prepopulation: "Succeded" });
  },
  pushOldPlatformData: () => {
    let schema = {};

    const dob = AppStore.getControlByName("date-of-birth");
    if (!isNullOrUndefined(dob))
      schema["age"] = dateParser.getDateDiffInYears(dob, dayjs()).toString();

    const vehicleusage = AppStore.getControlByName("bike-usage");
    if (!isNullOrUndefined(vehicleusage)) schema["vehicle-usage"] = vehicleusage;

    const manufacturer = AppStore.getControlByName("bike-manufacturer");
    if (!isNullOrUndefined(manufacturer)) schema["manufacturer"] = manufacturer;

    const model = AppStore.getControlByName("bike-model-description");
    if (!isNullOrUndefined(model)) schema["model"] = model.split(",")[0];

    const carowner = AppStore.getControlByName("car-owner");
    if (!isNullOrUndefined(carowner))
      schema["carowner"] = carowner === "yes" ? "True" : "False";

    const bikeType = AppStore.getControlByName("bike-type");
    if (!isNullOrUndefined(bikeType))
      schema["type-of-vehicle"] =
        bikeType !== null && bikeType !== "" ? bikeType : "Unknown";

    gtmDataLayer.push({
      event: "old_platform_data",
      form_data: schema,
      CookieConsent: cookies.get("CookieConsent"),
      quote_reference: AppStore.getQuoteReference(),
    });
  },
  pushOptionalExtrasFilterData: (
    eventCategory,
    eventAction,
    breakdown,
    legalProtection,
    personalAccident
  ) => {
    let event_label;

    if (breakdown == false && legalProtection == false && personalAccident == false)
      event_label = "None";

    if (breakdown == true && legalProtection == true && personalAccident == true)
      event_label = "BD-LE-PA";

    if (breakdown == true && legalProtection == false && personalAccident == false)
      event_label = "BD";

    if (breakdown == false && legalProtection == true && personalAccident == false)
      event_label = "LE";

    if (breakdown == false && legalProtection == false && personalAccident == true)
      event_label = "PA";

    if (breakdown == true && legalProtection == true && personalAccident == false)
      event_label = "BD-LE";

    if (breakdown == true && legalProtection == false && personalAccident == true)
      event_label = "BD-PA";

    if (breakdown == false && legalProtection == true && personalAccident == true)
      event_label = "LE-PA";

    gtmDataLayer.push({
      event: "OptionalExtrasFilter",
      optionalExtrasFilterData: {
        event_action: eventAction,
        event_page: "yourquotes",
        event_category: eventCategory,
        event_label: event_label,
      },
    });
  },
  pushRecallTracking: (data, utmTerm, type) => {
    let actionLabel, categoryLabel;

    if (type === "land") {
      actionLabel = "CTB";

      if (utmTerm === "view_all") actionLabel = "View";
      else if (utmTerm === "amend") actionLabel = "Amend";
    } else if (type === "validation") {
      categoryLabel = !isNullOrUndefined(data.Category) ? data.Category : "CTBValidation";

      if (utmTerm === "view_all") categoryLabel = "ViewValidation";
      else if (utmTerm === "amend" || utmTerm === "returnlink")
        categoryLabel = "AmendValidation";
    }

    gtmDataLayer.push({
      event: "RecallTracking",
      recallTrackingData: {
        event_action: !isNullOrUndefined(actionLabel) ? actionLabel : data.Action,
        event_page: data.Page,
        event_category: !isNullOrUndefined(categoryLabel) ? categoryLabel : data.Category,
        event_label: data.Label,
      },
    });
  },
  pushRegistrationLookupTracking(data) {
    let pageLabel = !isNullOrUndefined(data.Page) ? data.Page : "Vehicle Details";
    let actionLabel = !isNullOrUndefined(data.Action) ? data.Action : "Fail";
    let categoryLabel = !isNullOrUndefined(data.Category)
      ? data.Category
      : "Service called";

    gtmDataLayer.push({
      event: "VehicleLookup",
      vehicleLookupData: {
        event_action: actionLabel,
        event_page: pageLabel,
        event_category: categoryLabel,
        event_label: data.Label,
      },
    });
  },
  pushTimeToInteractive(controlName) {
    try {
      const controlToTrack =
        AppStore.getUserJourneySettings()?.generalSettings?.timeToInteractiveControl;

      if (controlToTrack && controlName === controlToTrack) {
        const navigationEntry = performance.getEntriesByType("navigation")[0];
        const navigationStartTime = navigationEntry ? navigationEntry.startTime : 0;
        const timeToInteractive = (
          (performance.now() - navigationStartTime) /
          1000
        ).toFixed(2);

        if (timeToInteractive > 0) {
          gtmDataLayer.push({
            event: "TimeToInteractive",
            timeToInteractive: timeToInteractive,
          });
        }
      }
    } catch (ex) {
      ClientLogging.logError("GTM pushTimeToInteractive", ex);
    }
  },
  pushErrorMessage: (controlName, errorMessage) => {
    gtmDataLayer.push({ event: "error-message", error_message: { field: controlName, message: errorMessage } });
  },
};
