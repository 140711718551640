import { getFirstInteraction, setFirstInteraction } from "@helpers/first-interaction";
import axios from "axios";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { Cookies as CSCookies } from "../constants";
import { cookies } from "../helpers/cookies";
import ClientLogging from "./loggingProvider";

/**
 * Class used to call ClickStream API Gateway
 */
class ClickStreamProvider {
  constructor() {
    this.sendCSData = import.meta.env.VITE_CLICKSTREAM_DATA_SEND;
  }

  /**
   * Check if csid cookie is present and if not insert it
   */
  setCSIDValueInCookie = () => {
    try {
      let csidCookieValue = this.getCSIDCookieValue();
      if (this.sendCSData === "true" && csidCookieValue === undefined) {
        let expiryTime = dayjs().add(1, "month");
        let uuid = this.getUUIDValue();
        cookies.set(CSCookies.CSCookie, uuid, {
          path: "/",
          expires: expiryTime.toDate(),
        });
      }
    } catch (error) {
      ClientLogging.logError(error, "ClickStream Provider - setCSIDValueInCookie error ");
    }
  };

  /**
   * Check if firstInteraction cookie is present and if not insert it
   */
  setFirstInteractionCookie = () => {
    const csidCookieValue = this.getCSIDCookieValue();

    if (
      this.sendCSData === "true" &&
      getFirstInteraction() === undefined &&
      csidCookieValue !== undefined
    ) {
      setFirstInteraction(true, undefined, { path: "/" });
    }
  };

  /**
   * Generic method used to push data to API Gateway
   */
  handleClickStreamEvent = async (evc, eva, evl, evv) => {
    try {
      let csidCookieValue = this.getCSIDCookieValue();
      if (
        this.sendCSData === "true" &&
        csidCookieValue !== undefined
      ) {
        await axios({
          method: "post",
          url: import.meta.env.VITE_CLICKSTREAM_API_ENDPOINT,
          data: {
            eid: uuidv4(),
            csid: csidCookieValue,
            d: window.location.hostname,
            sn: import.meta.env.VITE_SKIN,
            p: window.location.pathname,
            tt: dayjs(),
            cv: import.meta.env.VITE_VERSION,
            ec: evc,
            ea: eva,
            el: evl,
            ev: evv.toString(),
          },
          headers: { "Content-Type": "application/json" },
        })
          .then(() => { })
          .catch((error) => {
            ClientLogging.logError(
              error,
              "ClickStream Provider handler - Axios post error"
            );
          });
      }
    } catch (error) {
      ClientLogging.logError(error, "ClickStream Provider handler error");
    }
  };

  getCSIDCookieValue = () => {
    return cookies.get(CSCookies.CSCookie);
  };

  //Generating a random number between 2 inclusive values
  getRandomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  getUUIDValue = () => {
    let noOfUUIDs = this.getRandomNumber(1, 50);
    let uuids = [];

    //generating an array of UUIDs using
    //uuidv4 so we lower collision chances
    for (let i = 0; i < noOfUUIDs; i++) {
      uuids.push(uuidv4());
    }
    //returning an uuid based on a random array index
    return uuids[this.getRandomNumber(1, noOfUUIDs - 1)];
  };
}
export default new ClickStreamProvider();
